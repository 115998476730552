<template>
  <div>
    <img class="top" src="@/assets/top.jpg" alt="" />
    <!-- <img class="txt" src="@/assets/txt.png" alt="" /> -->
    <div class="txt">
        <div class="title">关于我们</div>
      <p>
        中云网络服务于网站建设、搜索引擎优化、品牌设计和品牌策划、电子商务等多维领域，用专业和责任使之紧密地联为一个有机整体，致力于提
        供客户完整的解决方案
      </p>
      <p>
        中云网络汇聚行业资源，与高校、猎头、多所研究机构保持着密切的合作关系以及人才输送，以服务为基础，双赢为目的，提出“乐在服务，华与你
        我”的概念。
      </p>
      <p>
        狭路相逢勇者胜，公司视“创新"为动力和源泉，要求”知勇创新，严守诚信”，注里团队协同合作，构筑中云特色的专业服务团队。
      </p>
      <p>
        以服务客户为准则，在掌握客户的需求后，悉心解答客户，把复杂问题简单化，专业问题生活化，给与客户合理的建议，为客户定制符合自身情
        况的解决方案。
      </p>
      <p>
        结合客户的经验，以第三人的视角跳出客户行业，用商业的角度看待，用艺术的眼光辅以实现，借助科学方法和跳跃性思维，为品牌创建骨骼。
        注入血液，融入文化情感，用理念来折射专业魅力。
      </p>
      <p>
        网络的本质是共享与开放，中云网络不断整合资源使之助力于客户，用开放思维注入产品，提供客户完整和高效的服务,实现客户商业目的及战略部署，再次合作率达到100%。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
  filters: {},
  watch: {},
};
</script>

<style scoped>
.top,
.txt {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.txt{
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
}
.txt p{
    text-indent: 2em;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.2em;
    color: #353434;
}
.title{
    display: inline-block;
    padding: 10px 20px;
    background-color: #409eff;
    color: white;
    font-weight: bold;
    letter-spacing: 0.2em;
}
</style>
